import { environment } from "environment";
import http from "./http";
import moment from "moment";
import Promisable from "./Promisable";
import { log } from "./logger.service";
import { AppDispatch } from "redux/store";
import { flightsActions } from "redux/slices/flights/flightsSlice";
import { airportsActions } from "redux/slices/airports/airportsSlice";
import { reset } from "redux-form";
import { modalActions } from "redux/slices/modal/modalSlice";

let url = environment.API_END_URL;

const FlightsService = {
  searchFlights: async (data: any, dispatch?: AppDispatch, navigate?: any) => {
    dispatch?.(airportsActions.setAirports(null));
    console.warn("Data", data);
    // const from_split = data.from.split("-");
    // const from_code = from_split[0];
    // const to_split = data.to.split("-");
    // const to_code = to_split[0];
    const passengersData = data?.passengers
      .split(", ")
      .reduce((acc: any, curr: any) => {
        const [key, value] = curr.split(": ");
        acc[key.trim()] = parseInt(value);
        return acc;
      }, {});

    const searchData = {
      from: data?.from,
      to: data?.to,
      // departure: moment(data.round_date.date[0]).format("YYYY-MM-DD"),
      // return: moment(data.round_date.date[1]).format("YYYY-MM-DD"),
      email: data?.email,
      phone: data?.phone?.formattedValue,
      name: data?.name,
      type: "return",
      adults: passengersData.adults,
      children: passengersData.children,
      infants: passengersData.infants,
      brand: "Business Class For Less",
      brand_code: "BCF",
      flightclass: "business",
      date: [
        moment(data.round_date.date[0]).format("YYYY-MM-DD"),
        moment(data.round_date.date[1]).format("YYYY-MM-DD"),
      ],
    };
    dispatch?.(flightsActions.setLoading(true));
    http.setHeaders();
    const { error, success }: any = await Promisable.asPromise(
      http.post(
        "https://panelapi.sufitravelandtours.co.uk/api/searchFlight",
        searchData
        // params: {
        //   adults: "1",
        //   origin: from_code,
        //   destination: to_code,
        //   departureDate: data?.round_date
        //     ? moment(data.round_date.date[0]).format("YYYY-MM-DD")
        //     : moment(data.departure.date).format("YYYY-MM-DD"),
        //   cabinClass: "business",
        //   currency: "USD",
        // },
        // headers: {
        //   "Content-Type": "application/json",
        //   //   "X-RapidAPI-Host": "skyscanner44.p.rapidapi.com",
        //   //   "X-RapidAPI-Key":
        //   //     "a916d4862amsh74e162cf6a4c5fep1f1e94jsnfd2b62703f5e",
        // },
      )
    );

    log("success", success);
    // if (success) {
    //   let flights = [];
    //   success.data.itineraries.results.forEach((element: any, index: any) => {
    //     flights.push(element);
    //     if (index > 3) return;
    //   });
    //   dispatch?.(flightsActions.setFlights(success.data.itineraries.results));
    //   navigate("/thanks");
    //   return success;
    // }

    dispatch?.(flightsActions.setLoading(false));
    navigate("/thanks");
    return error;
  },
  sendQuery: async (values: any, dispatch?: any, navigate?: any) => {
    const data = {
      from: values?.from,
      to: values?.to,
      departure: moment(values.round_date.date[0]).format("YYYY-MM-DD"),
      return: moment(values.round_date.date[1]).format("YYYY-MM-DD"),
      email: values?.email,
      phone: values?.phone?.formattedValue,
      name: values?.name,
      trip: "return",
      adults: values?.adults,
      children: values?.children,
      infants: values?.infants,
    };

    dispatch?.(flightsActions.setTripLoading(true));
    const { error, success }: any = await Promisable.asPromise(
      http.post(`${url}guest/addTrip`, data)
    );
    if (success) {
      dispatch?.(flightsActions.setTripLoading(false));
      dispatch(reset("OnewaySearchForm"));
      dispatch(reset("RoundTripSearchForm"));
      dispatch(reset("MulticitySearchForm"));
      navigate("/thanks");
      return success;
    }
    dispatch?.(flightsActions.setTripLoading(false));
    return error;
  },

  sendHomeQuery: async (values: any, dispatch?: any, navigate?: any) => {
    console.log({ values });

    const data = {
      from: values?.from,
      to: values?.to,
      departure: values?.round_date
        ? moment(values.round_date.date[0]).format("YYYY-MM-DD")
        : moment(values.departure.date).format("YYYY-MM-DD"),
      return: values?.round_date
        ? moment(values.round_date.date[1]).format("YYYY-MM-DD")
        : "",
      email: values?.email,
      phone: values?.phone?.formattedValue,
      name: values?.name,
      trip: "return",
      adults: values?.adults,
      children: values?.children,
      infants: values?.infants,
    };

    dispatch?.(flightsActions.setLoading(true));
    await Promisable.asPromise(http.post(`${url}guest/addTrip`, data));
    navigate("/thanks");
    return "";
  },
  requestCall: async (values: any, dispatch?: any, navigate?: any) => {
    const data = {
      name: values?.name,
      email: values?.email,
      phone: values?.phone?.formattedValue,
      message: values?.message,
    };

    dispatch?.(modalActions.setLoading(true));
    const { error, success }: any = await Promisable.asPromise(
      http.post(`${url}guest/requestCall`, data)
    );
    if (success) {
      dispatch?.(modalActions.setLoading(false));
      dispatch(reset("ContactForm"));
      dispatch(modalActions.closeModal());
      navigate("/");
      return success;
    }
    dispatch?.(modalActions.setLoading(false));
    return error;
  },
  sendEmailQuery: async (
    values: any,
    dispatch?: AppDispatch,
    navigate?: any
  ) => {
    const data = {
      from: values?.from,
      to: values?.to,
      departure: moment(values.round_date.date[0]).format("YYYY-MM-DD"),
      return: moment(values.round_date.date[1]).format("YYYY-MM-DD"),
      email: values?.email,
      phone: values?.phone?.formattedValue,
      name: values?.name,
      trip: "return",
      adults: values?.adults,
      children: values?.children,
      infants: values?.infants,
    };
  
    // Safely invoke dispatch if defined
    dispatch?.(flightsActions.setTripLoading(true));
  
    // const emailBody = `
    //   <h2>Booking Details</h2>
    //   <p><strong>From:</strong> ${data.from}</p>
    //   <p><strong>To:</strong> ${data.to}</p>
    //   <p><strong>Departure Date:</strong> ${data.departure}</p>
    //   <p><strong>Return Date:</strong> ${data.return}</p>
    //   <p><strong>Name:</strong> ${data.name}</p>
    //   <p><strong>Email:</strong> ${data.email}</p>
    //   <p><strong>Phone:</strong> ${data.phone}</p>
    //   <p><strong>Trip Type:</strong> ${data.trip}</p>
    //   <p><strong>Adults:</strong> ${data.adults}</p>
    //   <p><strong>Children:</strong> ${data.children}</p>
    //   <p><strong>Infants:</strong> ${data.infants}</p>
    // `;
  
    try {
      const { error, success }: any = await Promisable.asPromise(
        // http.post("https://businessclassforless.com:5000/send-email", {
          http.post("https://api.trexcrm.com/api/bcl-email", {
          // to: "connect@businessclassforless.com",
          // subject: "Inquiry From BCFL",
          // html: emailBody,
          data
        })
      );
  
      if (success) {
        // Safely reset forms and navigate if defined
        dispatch?.(flightsActions.setTripLoading(false));
        dispatch?.(reset("OnewaySearchForm"));
        dispatch?.(reset("RoundTripSearchForm"));
        dispatch?.(reset("MulticitySearchForm"));
        navigate?.("/thanks"); // Only navigate if the function exists
        return success;
      }
  
      console.error("Error OR Success:", error, success);
      dispatch?.(flightsActions.setTripLoading(false));
      return error;
    } catch (err) {
      // Handle any network or unexpected errors
      console.error("Error sending email:", err);
      dispatch?.(flightsActions.setTripLoading(false));
      return err;
    }
  },
  
};

export default FlightsService;
